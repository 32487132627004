import React, { useState, useEffect, useRef, useCallback } from 'react';
import Box from '@mui/material/Box';

import Masonry from '@mui/lab/Masonry';
import { config } from '../Constants'

import Sponsored from './Spon_Card'
import MemeCard from './Meme_Card'

const axios = require('axios').default;


function UserBookSearch(pageNumber) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(true)
  const [books, setBooks] = useState([])
  const [hasMore, setHasMore] = useState(false)


  useEffect(() => {
    // console.log('Called Axios')
    setLoading(true)
    setError(false)

    const url = config.url.API_URL

    // let cancel
    axios
      .get(
        url,
        {
          params: { page: pageNumber },
          // cancelToken: new axios.CancelToken(c => cancel = c)
        }
      )
      .then(res => {
        // console.log("data: ", res.data)
        setBooks(prevBook => {
          return [...new Set([...prevBook, ...res.data.map(b => JSON.stringify(b))])]
          // return [...new Set([...prevBook, ...res.data.map(b => [b.title, b.description, b.size].toString())])]
        })
        setHasMore(res.data.length > 0)
        setLoading(false)
      })
    // .catch(e => { if (axios.isCancel(e)) return setError(true) })
    // return () => cancel()

  }, [pageNumber])

  return { loading, error, books, hasMore }
}

export default function ImageMasonry() {
  const [pageNumber, setPageNumber] = useState()
  const observer = useRef()

  const { loading, books, hasMore, error } = UserBookSearch(pageNumber)

  const lastBookElementRef = useCallback(node => {
    if (loading) { return }
    if (observer.current) { observer.current.disconnect() }
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore) {
        console.log(books)
        const slug = JSON.parse(books[books.length - 1])
        console.log(slug)
        setPageNumber(slug.title)

      }
    })
    if (node) {
      observer.current.observe(node)
    }
    // console.log(node)
  }, [loading, hasMore, books])


  const add_spons = (arr) => {
    const ads = false
    const arr_spons = []

    const every = 23
    for (let i = 0; i < arr.length; i++) {
      if (ads) {
        if ((i + 12) % every === 0) {
          arr_spons.push({
            id: 'sponsored',
            description: 'Don\'t miss the next bull run!\nPre-order yours today!',
            url: 'https://espiboz.com',
            likes: false
          })
        }
      }

      const line = JSON.parse(arr[i])
      const [width, height] = line.size.slice(1, -1).split(',')
      line.width = width
      line.height = height
      arr_spons.push(line);
    }
    return arr_spons
  }

  return (
    <Box
      sx={{ width: '100%', minHeight: 829 }}
      className="pt-4"

    >

      <div>{error && 'Error...'}</div>

      <Masonry
        columns={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 6 }}
        spacing={2}
      >
        {add_spons(books).map((item, index) => (
          (item.id === 'sponsored') ? <Sponsored key={index} item={item} /> :
            (books.length === index + 1) ?
              <div ref={lastBookElementRef} key={index}><MemeCard item={item} index={index} /></div> :
              <div key={index}><MemeCard item={item} index={index}  /></div>

        ))}
        {/* // <ItemCard item={bookParser(item)} index={index} key={index}/> */}
      </Masonry>

      <div className='ocean_cont'>{loading &&
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
          <div className="wave_load bitter" textAlign="center">Loading More</div>
        </div>
      }</div>
      <div className='ocean_cont'>{!hasMore &&
        <div className="ocean">
          <div className="wave"></div>
          <div className="wave"></div>
          {/* <div class="wave_load bitter" textAlign="center">You've reached the Bottom of the Meme Abyss</div> */}
          <div className="wave_load bitter" textAlign="center">YOU'VE REACHED THE BOTTOM OF THE MEME ABYSS</div>
        </div>
      }</div>
    </Box>
  );
}