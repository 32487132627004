import React from 'react'

import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
// import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { styled } from '@mui/material/styles';
import placeholderSrc from "../placeholder.png"

import { useNavigate } from 'react-router-dom';
import { config } from '../Constants'

import ShareIcon from '@mui/icons-material/Share';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
// import HeartBrokenOutlinedIcon from '@mui/icons-material/HeartBrokenOutlined';
// import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import TwitterIcon from '@mui/icons-material/Twitter';
// import FacebookIcon from '@mui/icons-material/Facebook';


import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


// import Icon from '@mui/material/Icon'

const axios = require('axios').default;

const ITEM_HEIGHT = 48;


const LabelMid = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 0,
  borderBottomLeftRadius: 0,
}));

const Label = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(0.5),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomRightRadius: 10,
  borderBottomLeftRadius: 10,
}));

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'center',
//   color: theme.palette.text.secondary,
// }));



export default function MemeCard(props) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    // console.log(props)
    const url = `https://stockmemes.co/${props.item.title}`

    const msg = `${props.item.description} ${url}`
    const twitter = `https://twitter.com/intent/tweet?text=${encodeURIComponent(msg)}`
    // const facebook = `https://twitter.com/intent/tweet?text=${encodeURIComponent(msg)}`
    // console.log(e.target.social)
    // console.log(e.target.getAttribute('social'))
    if (e === "Link Copied") {
      navigator.clipboard.writeText(url);
    } else {
      const action = e.target.getAttribute('social')
      if (action === "copy") {
        // handleSnackClick('Message A')
        navigator.clipboard.writeText(url);
      } else if (action === 'twitter') {
        window.open(twitter, "_blank");
      }
    }

    setAnchorEl(null);

  };

  const [snackPack, setSnackPack] = React.useState([]);
  const [snackOpen, setSnackOpen] = React.useState(false);
  const [messageInfo, setMessageInfo] = React.useState(undefined);
  const [liked, setLiked] = React.useState(props.item.likes.client);
  const [likes, setLikes] = React.useState(props.item.likes.count);
  
  React.useEffect(() => {
    if (snackPack.length && !messageInfo) {
      // Set a new snack when we don't have an active one
      setMessageInfo({ ...snackPack[0] });
      setSnackPack((prev) => prev.slice(1));
      setSnackOpen(true);
    } else if (snackPack.length && messageInfo && open) {
      // Close an active snack when a new one is added
      setSnackOpen(false);
    }
  }, [snackPack, messageInfo, open]);

  const handleSnackClick = (message) => () => {

    setSnackPack((prev) => [...prev, { message, key: new Date().getTime() }]);
    console.log(message)
    if (message === "Link Copied") {
      handleClose(message)
    }
  };

  const handleSnackClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackOpen(false);
  };

  const handleExited = () => {
    setMessageInfo(undefined);
  };



  const imgSrc = `${config.url.CDN_URL}/memes/${props.item.title}.png`

  const navigate = useNavigate();

  const handleRedirect = (event) => {
    event.preventDefault();
    navigate(`/${props.item.title}`, {
      state: {
        tagline: props.item.description,
        size: [props.item.width, props.item.height]
      }
    });
  }

  const handleLike = () => {
    const url = `${config.url.API_URL}/like`
    axios
      .get(
        url, { params: {meme: props.item.title} }
      )
      .then(res => {
        console.log(res)
        if (res.data.status === "Liked") {
          setLiked(true)
          setLikes(prevState => {
            return prevState + 1
          })
        } else if (res.data.status === "Disliked") {
          setLiked(false)
          setLikes(prevState => {
            return prevState - 1
          })
        }
      })
  }



  const ProgressiveImg = ({ placeholderSrc, src, ...props }) => {
    return (
      <img
      style={{aspectRatio: props.aspectRatio}}
        {...{ src: placeholderSrc, ...props }}
        alt={props.alt || ""}
        className="image"
      />
    );
  };


return (
  <div key={props.index} className="card" >
    <a
      href={`/${props.item.title}`} 
      onClick={handleRedirect}
      rel="no-refresh"
    >
      <ProgressiveImg
        id={props.item.title}
        placeholderSrc={placeholderSrc}
        src={imgSrc}
        srcSet={imgSrc}
        alt={props.item.description}
        loading="lazy"
        style={{
          aspectRatio: props.item.width / props.item.height,
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
          display: 'block',
          width: '100%',
        }}
      />
    </a>
    <LabelMid className='bitter'>{props.item.description}</LabelMid>
    <Label className='bitter'>


      <ButtonGroup className="action_btns" variant="text" size='small' fullWidth>
        <Tooltip title={<div style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{"Like"}</div>} multiline="true">
          <Button onClick={handleLike}>
            {liked ?
              <FavoriteIcon fontSize="small" sx={{ color: 'rgb(255, 0, 0)' }} />
              :
              <FavoriteBorderIcon fontSize="small" sx={{ color: 'rgb(255, 0, 0)' }} />
            }
            {likes !== 0 && <div className="likes">{likes}</div>}
          </Button>
        </Tooltip>
        <Tooltip title="Share">
          <Button
            aria-label="more"
            id="long-button"
            aria-controls={open ? 'long-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <ShareIcon fontSize="small" sx={{ color: 'rgb(0, 191, 191)' }} />
          </Button>
        </Tooltip>
        {/* <Tooltip style={{whiteSpace: 'pre-line'}} title={"Report \n Under Development"} multiline={true}> */}
        <Tooltip title={<div style={{ textAlign: 'center', whiteSpace: 'pre-line' }}>{"Report\nUnder Development"}</div>} multiline="true">
          <Button>
            <ReportProblemOutlinedIcon fontSize="small" sx={{ color: 'rgb(255, 127, 0)' }} />
          </Button>
        </Tooltip>

        <Menu
          id="long-menu"
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '20ch',
            },
          }}
        >

          <MenuItem className="font_small" social='copy' onClick={handleSnackClick("Link Copied")}>
            <ContentCopyIcon /> - Copy Link
          </MenuItem>
          <MenuItem className="font_small" social='twitter' onClick={handleClose}>
            {/* <MenuItem onClick={handleSnackClick('Message A')} className="font_small" social='twitter' > */}
            <TwitterIcon /> - Twitter
          </MenuItem>
          {/* <Button onClick={handleSnackClick('Message A')}>Show message A</Button> */}
          {/* <Button onClick={handleSnackClick('Message B')}>Show message B</Button> */}
          {/* <MenuItem className="font_small" onClick={handleClose}>
            <FacebookIcon /> - Facebook
          </MenuItem> */}

        </Menu>


      </ButtonGroup>



      <Snackbar
        key={messageInfo ? messageInfo.key : undefined}
        open={snackOpen}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        TransitionProps={{ onExited: handleExited }}
        message={messageInfo ? messageInfo.message : undefined}
        action={
          <React.Fragment>
            {/* <Button color="secondary" size="small" onClick={handleSnackClose}>
              UNDO
            </Button> */}
            <IconButton
              aria-label="close"
              color="inherit"
              sx={{ p: 0.5 }}
              onClick={handleSnackClose}
            >
              <CloseIcon />
            </IconButton>
          </React.Fragment>
        }
      />


    </Label>
  </div>
)
}
