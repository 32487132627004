import React from 'react'
import Spons_img from '../spons.jpg'
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
// import placeholderSrc from "../placeholder.png"

const Label = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 10,
    borderBottomLeftRadius: 10,
  }));

export default function Sponsored (props) {
    const handleSponRedirect = (e) => {
      // const url = 'https://www.kickstarter.com/projects/killcrew/iboz-esp32-1602-universal-tracker'
      const newWindow = window.open(props.item.url, '_blank', 'noopener,noreferrer')
      if (newWindow) newWindow.opener = null
      // console.log("HEHE",e.target.id)
      // console.log(props.item[2], props.item[3])
      // navigate(`/${e.target.id}`, {state:{
      //   tagline: e.target.alt,
      //   size: [props.item[2], props.item[3]]
      // }});
    }
    return (
  
      <div key={props.index} className="card_sponsored" >
          <img
            onClick={handleSponRedirect}
            src={Spons_img}
            srcSet={Spons_img}
            alt={props.item.description}
            loading="lazy"
            // aspectration={props.item[2] / props.item[3]}
            style={{
              borderTopLeftRadius: 10,
              borderTopRightRadius: 10,
              display: 'block',
              width: '100%',
            }}
            />
          <Label className='bitter text-black spons_text new-line'>{props.item.description}</Label>
          <Label className='bitter text-red'>SPONSORED</Label>
      </div>
    )
  }
