import React, { useState } from 'react';
// import { Formik } from "formik";
// import ReCAPTCHA from "react-google-recaptcha";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import TextField from '@mui/material/TextField';
import ReCAPTCHA from "react-google-recaptcha";
import FormControl from '@mui/material/FormControl';
import { config } from '../Constants'

const axios = require('axios').default;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    borderRadius: '15px',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'background.paper',
    border: "5px rgb(150, 150, 150) solid",
    boxShadow: 24,
    minHeight: '40vh',
    p: 4,
    overflow: 'hidden',
};


function Thumb(props) {
    return (
        <div className="mt-2" >
            Preview
            <img width="100%" alt="whatever" src={URL.createObjectURL(props.file[0])} />
        </div>
    )
}

export default function FileUpload(props) {
    const [error, setError] = React.useState()
    const recaptchaRef = React.useRef();

    const onSubmitWithReCAPTCHA = async () => {
        const token = await recaptchaRef.current.executeAsync();
        console.log(token)

        const formData = new FormData();

        // Update the formData object

        console.log("title:", title)
        formData.append("token", token);
        if (title === undefined){
            formData.append("name", "");
        } else {
            formData.append("name", title);
        }
        formData.append("image", file[0]);
        console.log(formData)
        axios.post(
            `${config.url.API_URL}/upload`,
            formData,
            {
                headers: { 'Content-Type': 'multipart/form-data' }
            }
        ).then(res => {
            console.log(res)
            updateTitle("")
            updateFile(null)
            updateFileValue("")
            if (res.data ===  true){
                setError(undefined)
                props.handleClose()
                window.location.reload(false)
            } else {
                setError(res.data)
            }
        })

        // apply to form data
    }
    const [file, updateFile] = useState(null);
    const [fileValue, updateFileValue] = useState();
    const [title, updateTitle] = useState();

    const handleFile = (e) => {
        console.log(e)
        updateFileValue(e.target.value)
        updateFile(e.target.files)
    }

    const handleTitle = (e) => {
        updateTitle(e.target.value)
    }


    // const formSubmit = () => {

    //     // alert("Submit Form")
    // }

    return (
        <Box sx={style}>
            <Typography component="span" id="modal-modal-title" variant="h6">
                Upload Meme
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            <FormControl fullWidth onSubmit={onSubmitWithReCAPTCHA}>
                <Typography component="span" id="modal-modal-description" sx={{ mt: 2 }}>
                    <TextField
                        id="standard-basic"
                        variant="standard"
                        label="Title"
                        value={title}
                        onChange={handleTitle}
                        fullWidth
                    />
                </Typography>
                <Typography component="span" id="modal-modal-file" sx={{ mt: 2 }}>
                    <TextField
                        fullWidth
                        name='upload-meme'
                        type="file"
                        value={fileValue}
                        // hidden
                        onChange={handleFile}
                    />
                    {file === null ? "" : <Button fullWidth variant='contained' color="success" className='mt-2' onClick={onSubmitWithReCAPTCHA}>Upload</Button>}
                    {file === null ? "" : <Thumb file={file} />}

                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey="6Lf-IZogAAAAAOoEwkoWvXCI8vcmt3fW8qGD9oTv"
                    />
                </Typography>
            </FormControl>
        </Box>
    )
}



