import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { config } from '../Constants'
import { Helmet } from 'react-helmet'
import axios from 'axios';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  pt: 0,
};


export function useKeypress(key, action) {
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === key) action()
    }
    window.addEventListener('keyup', onKeyup);
    return () => window.removeEventListener('keyup', onKeyup);
  }, [key, action]);
}

export default function Meme(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [tagline, setTagline] = useState();
  const [size, setSize] = useState([]);
  const [data, setData] = useState({});
  const handleClose = () => {
    navigate('/');
    setOpen(false);
  }


  const { meme_id } = useParams();
  const location = useLocation();

  const img_url = `${config.url.CDN_URL}/memes/${meme_id}.png`

  useKeypress('ArrowLeft', () => {
    if (data.next !== undefined) navigate(`/${data.next}`)
  });

  useKeypress('ArrowRight', () => {
    if (data.prev !== undefined) navigate(`/${data.prev}`)
  });


  useEffect(() => {
    // if (location.state != null) {
    //   setTagline(location.state.tagline)
    //   setSize(location.state.size)
    // } else {
    const url = `${config.url.API_URL}/meme`
    axios.get(url, { params: { meme: meme_id } })
      .then(res => {
        setTagline(res.data.description)
        setData(res.data)
        const [width, height] = res.data.size.split(',')
        setSize([width.substring(1).trim(), height.slice(0, -1).trim()])
      })
    // }

  }, [location.state, meme_id])





  const handleNext = (event) => {
    event.preventDefault();
    navigate(`/${data.next}`);
  }
  const handlePrev = (event) => {
    event.preventDefault();
    navigate(`/${data.prev}`);
  }

  return (
    <div>
      <Helmet>
        <title>Stock Memes</title>
        {/* <description>{tagline}</description> */}
        <meta name="description" content={tagline} />

        <link rel="canonical" href={`${config.url.DOMAIN}${location.pathname}`} />
        <meta property="og:image" content={img_url} />
        <meta property="og:url" content={`${config.url.DOMAIN}/${location.pathname}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Stock Memes" />
        <meta property="og:description" content={tagline} />
        <meta property="og:image:width" content={size[0]} />
        <meta property="og:image:height" content={size[1]} />


        {/* <meta name="twitter:title" content="Stock Memes" /> */}
        {/* <meta name="twitter:description" content={tagline} /> */}
        <meta name="twitter:image" content={img_url} />
        <meta name="twitter:card" content="summary_large_image" />

      </Helmet>
      <Modal
        className="Meme_Modal"
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="Meme_Modal_Inside">
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            <img
              src={img_url}
              srcSet={img_url}
              alt={img_url}
              loading="lazy"
              style={{
                borderTopLeftRadius: 10,
                borderTopRightRadius: 10,
                display: 'block',
                // height: '75vh',
                maxHeight: '75vh',
                // width: '100%',
                maxWidth: '80vw',
              }}
            />
          </Typography>
          <Typography id="modal-modal-title bitter" variant="h6" component="h2" align="center">
            {tagline}
          </Typography>

          <div id="modal-modal-title bitter" style={{ width: '100%', display: 'inline-flex',  marginTop: '10px'}} variant="h6" component="div" align="center" fullWidth>

            <div style={{ width: '50%', }}>
              {data.next &&
                <Button className="img_nav_btn" variant="outlined" fullWidth>
                  <a
                    href={`/${data.next}`}
                    onClick={handleNext}
                    rel="no-refresh"
                  >
                    Next
                  </a>
                </Button>
              }
            </div>
            <div style={{ width: '50%' }}>
              {data.prev &&
                <Button className="img_nav_btn" variant="outlined" fullWidth>
                  <a
                    href={`/${data.prev}`}
                    onClick={handlePrev}
                    rel="no-refresh"
                  >
                    Previous
                  </a>
                </Button>
              }
            </div>

          </div>
        </Box>
      </Modal>
    </div>
  );
}
