import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
// import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
// import Menu from '@mui/material/Menu';
// import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
// import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
// import Tooltip from '@mui/material/Tooltip';
// import MenuItem from '@mui/material/MenuItem';

import BasicModal from './Modal';
// import DropzoneDialogExample from './DropZone';

// const pages = ['Upload'];
const pages = [];
// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = () => {
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    //   const [anchorElUser, setAnchorElUser] = React.useState(null);

    // const handleOpenNavMenu = (event) => {
    //     setAnchorElNav(event.currentTarget);
    // };
    //   const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    //   };

    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    // };

    //   const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    //   };

    return (
        <AppBar className="navbar" position="static">
            <Box component="div" sx={{ display: { xs: 'none', md: 'none', lg: 'block' } }}>
                <div className="svg_container" >
                    <svg viewBox="0 0 500 100" preserveAspectRatio="xMinYMin meet">
                        {/* <path d="M0,100 C150,200 350,0 500,100 L500,00 L0,0 Z" style={{ stroke: 'none', fill: 'red' }}></path> */}
                        <path d="M0,15 C160,30 210,0 500,35 L500,00 L0,0 Z" style={{ stroke: 'none', fill: 'yellow' }} />
                        <path id="curve" d="M0,15 C154,25 204,0 500,29 L500,00 L0,0 Z" style={{ stroke: 'none', fill: 'blue' }} />
                        <path d="M0,15 C155,25 205,0 500,30 L500,00 L0,0 Z" style={{ stroke: 'none', fill: 'blue' }} />
                        <path d="M0,15 C150,20 200,0 500,25 L500,00 L0,0 Z" style={{ stroke: 'none', fill: '#1c1c1c' }} />
                        <text x='89%' fontSize="5px" fill='white' className='bitter'>
                            <textPath xlinkHref="#curve">We stand with Ukraine</textPath>
                        </text>
                    </svg>
                </div>
            </Box>
            {/* Large Screen */}
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="/"
                        className='text_logo bitter'
                        sx={{
                            mr: 2,
                            display: { xs: 'none', md: 'flex' },
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Stock Memes
                    </Typography>

                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem key={page} onClick={handleCloseNavMenu}>
                                    <Typography textAlign="center">{page}</Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box> */}

                    {/* Small Screen */}
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        className='text_logo bitter'
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        Stock Memes
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pages.map((page) => (
                            <Button
                                className="bitter navbtn"
                                key={page}
                                // onClick={handleCloseNavMenu}
                                sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>

                    {/* <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
                    <BasicModal />
                    {/* <DropzoneDialogExample /> */}
                </Toolbar>
            </Container>

        </AppBar>
    );
};
export default ResponsiveAppBar;
