
import './style.css';
// import PinterestLayout from './components/PinterestLayout';
import BasicMasonry from './components/Masonry';
import NavBar from './components/NavBar';
import { Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { config } from './Constants'

function App() {


  return (
    <div className="App">
      <Helmet>
        <title>Stock Memes</title>
          <meta name="description" content="Stock Market can be hard, so we might as well laugh along the way." />

          <link rel="canonical" href={`${config.url.DOMAIN}`} />
          {/* <meta property="og:image" content={img_url} />
          <meta property="og:url" content={`${config.url.DOMAIN}/${location.pathname}`} />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Stock Memes" />
          <meta property="og:description" content={tagline} />

          <meta name="twitter:title" content="Stock Memes" />
          <meta name="twitter:description" content={tagline} />
          <meta name="twitter:image" content={img_url} />
          <meta name="twitter:card" content="summary_large_image" /> */}

        </Helmet>
      <NavBar />
      <div className='App-container-90'>
        <BasicMasonry  />
      </div>
      <Outlet />
    </div>
  );
}

export default App;
