import React from 'react';
// import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Meme from './views/Meme'
import { hydrate, render } from "react-dom";

// const root = ReactDOM.createRoot(document.getElementById('root'));
const root = document.getElementById("root");
if (root?.hasChildNodes()) {
  hydrate(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
          <Route path="/:meme_id" element={<Meme />} />
        </Route>
      </Routes>
    </BrowserRouter>,
    root
  )
} else {
  render(
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} >
          <Route path="/:meme_id" element={<Meme />} />
        </Route>
      </Routes>
    </BrowserRouter>,
    root
  )
}
// root.render(
//   <BrowserRouter>
//     <Routes>
//       <Route path="/" element={<App />} >
//         <Route path="/:meme_id" element={<Meme />} />
//       </Route>
//     </Routes>
//   </BrowserRouter>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
