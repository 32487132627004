// Constants.js
const prod = {
    url: {
        API_URL: 'https://api.stockmemes.co',
        CDN_URL: 'https://cdn.stockmemes.co',
        DOMAIN: 'https://stockmemes.co',
    }
}
const dev = {
    url: {
        API_URL: 'https://api.stockmemes.co',
        CDN_URL: 'https://cdn.stockmemes.co',
        DOMAIN: 'https://stockmemes.co',
    }
}

// const dev = {
//     url: {
//         API_URL: 'http://127.0.0.1:8000',
//         CDN_URL: 'http://127.0.0.1:8000',
//         DOMAIN: 'http://127.0.0.1:3000',
//     }
// }

export const config = process.env.NODE_ENV === 'development' ? dev : prod;